import clsx from 'clsx';
import styles from './mobileMenu.module.scss';
import Link from 'next/link';
import { useUser } from '../../context/user';
import { Fragment } from 'react';

const MobileMenu = ({ open, links, onClose, onLogout }) => {
  const onClickContactUs = () => onClose();
  const { isLoggedIn } = useUser();

  return (
    <>
      <div
        className={clsx(styles.menuOverlay, open && styles.open)}
        onClick={onClose}
      />
      <div className={clsx([styles.menu, open && styles.open])}>
        <ul>
          {links.map((link) => (
            <Fragment key={link.label}>
              <li>
                <button
                  className={styles.menu__item}
                  onClick={() => {
                    onClose();
                    link.onClick();
                  }}
                >
                  {link.label}
                </button>
              </li>
              {link.children?.map((child) => (
                <li key={child.label}>
                  <button
                    className={clsx(
                      styles.menu__item,
                      styles["menu__item--subItem"]
                    )}
                    onClick={() => {
                      onClose();
                      child.onClick();
                    }}
                  >
                    {child.label}
                  </button>
                </li>
              ))}
            </Fragment>
          ))}
          {isLoggedIn() ? (
            <li>
              <button className={styles.menu__item} onClick={onLogout}>
                Log out
              </button>
            </li>
          ) : (
            <>
              <li>
                <Link href="/#footer" passHref>
                  <a className={styles.menu__item} onClick={onClickContactUs}>
                    Contact us
                  </a>
                </Link>
              </li>
              <li>
                <Link href="/signup" passHref>
                  <a className={styles.menu__item}>Sign up</a>
                </Link>
              </li>
              <li>
                <Link href="/login" passHref>
                  <a className={styles.menu__item}>Log in</a>
                </Link>
              </li>
            </>
          )}
        </ul>
      </div>
    </>
  );
};

export default MobileMenu;
