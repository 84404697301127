import styles from './dropdown.module.scss';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const DropdownMenu = ({ items }) => {
  return (
    <div className={styles.dropdownMenu}>
      <ul className={styles.menu}>
        {items.map((item) => {
          const itemClasses = clsx([
            styles.menuItem,
            item.onClick && !item.disabled && styles.clickable,
            item.disabled && styles.disabled,
            ...(item.classNames ? item.classNames : []),
          ]);
          return (
            <li
              key={item.label}
              className={itemClasses}
              onClick={item.onClick && !item.disabled ? item.onClick : null}
            >
              {item.label}
            </li>
          );
        })}
      </ul>
    </div>
  );
};


DropdownMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
      ]).isRequired,
      onClick: PropTypes.func,
    })
  )
};

export default DropdownMenu;

