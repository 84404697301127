import { useState } from 'react';
import { usePopper } from 'react-popper';
import PropTypes from 'prop-types';
import style from './dropdown.module.scss';
import clsx from 'clsx';

const Dropdown = ({ open, placement, menu, children }) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [popperOpen, setPopperOpen] = useState(open);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: placement || "bottom",
    modifiers: [
      {
        name: "offset",
        enabled: true,
        options: {
          offset: [0, 10]
        }
      }
    ]
  });

  const dropdownClasses = clsx([
    style.dropdown,
    popperOpen && style.open,
  ]);

  const overlayClasses = clsx([
    style.overlay,
    popperOpen && style.open,
  ]);

  return (
    <>
      <div
        className={style.popperRef}
        ref={setReferenceElement}
        onClick={() => setPopperOpen(!popperOpen)}
      >
        {children}
      </div>

      <div className={overlayClasses} onClick={() => setPopperOpen(false)} />
      <div
        className={dropdownClasses}
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
      >
        {menu}
      </div>
    </>
  );
};

Dropdown.propTypes = {
  open: PropTypes.bool,
  menu: PropTypes.node,
  placement: PropTypes.oneOf([
   'auto', 'auto-start' ,'auto-end' ,'top' ,'top-start',
    'top-end', 'bottom', 'bottom-start', 'bottom-end', 'right',
    'right-start', 'right-end', 'left', 'left-start', 'left-end'
  ]),
};

Dropdown.defaultProps = {
  open: false,
};

export default Dropdown;
