import styles from './userProfile.module.scss';
import Dropdown from '../dropdown/Dropdown';
import DropdownMenu from '../dropdown/DropdownMenu';
import { useState } from 'react';
import { useRouter } from 'next/router';
import ProfilePic from '../profilePic/ProfilePic';

const UserProfile = ({ user, onLogout }) => {
  const router = useRouter();
  const [menuOpen, setMenuOpen] = useState(false);
  const { full_name } = user;

  const menuItems = [
    {
      label: <>Logged in as: <b>{full_name}</b></>,
      classNames: [styles.menu__item, styles.menu__itemInfo],
    },
    {
      label: 'Profile',
      onClick: () => router.push('/preferences'),
      classNames: [styles.menu__item],
    }
  ];

  return (
    <div className={styles.user}>
      <p className={styles.user__text}>{user.full_name}</p>
      <Dropdown
        open={menuOpen}
        placement={"bottom-end"}
        menu={<DropdownMenu items={menuItems} />}
      >
        <div
          className={styles.user__avatar}
          onClick={() => setMenuOpen((prevState) => !prevState.menuOpen)}
        >
          <div className={styles.user__profilePic}>
            <ProfilePic
              img={user.profile_picture}
              name={user.full_name}
              medium
              includeInitials
            />
          </div>
        </div>
      </Dropdown>
      <button className={styles.user__text} onClick={onLogout}>
        Log out
      </button>
    </div>
  );
};

export default UserProfile;
