import styles from './profilePic.module.scss';
import clsx from 'clsx';

const ProfilePic = ({ img, name, includeInitials=false, small=false, medium=false, tiny=false }) => {
  const initials = name
    ?.split(" ")
    .map((name) => name.charAt(0).toUpperCase())
    .slice(0, 3)
    .join("");

  return (
    <div
      className={clsx(
        styles.proPic,
        !img && styles["proPic--noImg"],
        small && styles["proPic--small"],
        medium && styles["proPic--medium"],
        tiny && styles["proPic--tiny"],
      )}
    >
      {img ? (
        <img src={img} alt={`${name} profile picture`} />
      ) : (
        <span>{includeInitials ? initials : ""}</span>
      )}
    </div>
  );
};

export default ProfilePic;

