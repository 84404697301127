import styles from './newHeaderNav.module.scss';
import Link from 'next/link';
import clsx from 'clsx';
import { useState, useEffect } from 'react';
import MobileMenu from './MobileMenu';
import { useUser } from '../../context/user';
import UserProfile from './UserProfile';
import axios from 'axios';
import { useRouter } from 'next/router';
import { useScrollLock } from '../../hooks/useScrollLock';
import ProfilePic from '../profilePic/ProfilePic';
import { useDashboard } from '../../context/dashboard';
import { useCompany } from '../../context/company';
import { useApi } from '../../context/api';

const NewHeaderNav = ({
  centerLinks = [],
  mobileLinks = [],
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { isLoggedIn, user, clearUser } = useUser();
  const { clearCompany } = useCompany();
  const { clearApi } = useApi();
  const { clearDashboard } = useDashboard();
  const router = useRouter();
  const href = isLoggedIn() ? "/my-investments" : "/";
  const { lockScroll, unlockScroll } = useScrollLock();

  const logout = async () => {
    await axios.post("/api/logout");
    clearUser();
    clearDashboard();
    clearCompany();
    clearApi();
    router.push("/");
  };

  useEffect(() => {
    if (menuOpen)
      lockScroll();
    else
      unlockScroll();
  }, [menuOpen]);

  return (
    <>
      <header className={clsx([styles.header, menuOpen && styles.menuOpen])}>
        <div className={styles.header__wrapper}>
          <nav className={styles.header__nav}>
            <div className={styles.header__start}>
              <Link href={href}>
                <a>
                  <img
                    src="/cap-logo.svg"
                    alt="Cap! Logo"
                    className={styles.header__logo}
                  />
                </a>
              </Link>
            </div>

            <div className={clsx([styles.header__center, "show-for-large"])}>
              <div className={clsx([styles.header__centerLinksWrapper])}>
                {centerLinks.map((link) => {
                  return (
                    <button
                      className={clsx([
                        styles.header__link,
                        link.active && styles["header__link--active"],
                      ])}
                      onClick={link.onClick}
                      key={link.label}
                    >
                      {link.label}
                    </button>
                  );
                })}
              </div>
            </div>

            <div className={styles.header__end}>
              {isLoggedIn() ? (
                <UserProfile user={user} onLogout={logout} />
              ) : (
                <>
                  <Link href="/signup" passHref>
                    <a
                      className={clsx([styles.header__link, "show-for-large"])}
                    >
                      Sign up
                    </a>
                  </Link>
                  <Link href="/login" passHref>
                    <a
                      className={clsx([styles.header__link, "show-for-large"])}
                    >
                      Log in
                    </a>
                  </Link>
                </>
              )}

              <div className={clsx("hide-for-large", styles.mobile)}>
                {isLoggedIn() && (
                  <ProfilePic
                    img={user.profile_picture}
                    name={user.full_name}
                    tiny
                    includeInitials
                  />
                )}

                <button
                  className={clsx([
                    styles.header__link,
                    styles.header__menu,
                  ])}
                  onClick={() => setMenuOpen((menuOpen) => !menuOpen)}
                >
                  <img src="/icons/menu.svg" alt="Menu" />
                </button>
              </div>
            </div>
          </nav>
        </div>
      </header>
      <MobileMenu
        open={menuOpen}
        links={mobileLinks}
        onClose={() => setMenuOpen(false)}
        onLogout={logout}
      />
    </>
  );
};

export default NewHeaderNav;

